import { Table, Input, Button, PageHeader } from "antd"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchGeneric } from "../../services/api";

const { Search } = Input;

const CustomTable = ({ columns, url, title = '', onCancel, editForm, createForm, edit, children}) => {
    const [page, setPage] = React.useState(1)
    const [filterText, setFilterText] = React.useState('');
    const [sorter, setSorter] = React.useState({ sort: 'id', direction: 'desc'});
    const [editId, setEditId] = useState(null)
    const [createVisible, setCreateVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    const [filters, setFilters] = useState({});

    const onSearch = value => {
        setFilterText(value);
        setPage(1)
    };

    useEffect(() => { 
        setEditId(edit)
        if (edit) {
            setEditVisible(true)
        }
    }
    , [ edit])

    const onCancelOrClose = ()=> {
        setCreateVisible(false)
        setCreateVisible(setEditVisible)
        if( onCancel)
             onCancel()
    }
    const {
        isLoading,
        data,
        refetch,
    } = useQuery(['companies',url, page, filterText, filters, sorter], () => fetchGeneric(url, { page, filterText, ...sorter , ...filters}), { keepPreviousData: false})

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)

      //  console.log(sorter);
        setSorter({
            sort: sorter.columnKey,
            direction: sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : '',
        })
    };

    const EditForm = editForm;
    const CreateForm = createForm;

    const applyFilter = (newFilters)=> {
        setFilters({...filters,...newFilters})
    }

    const resetFilters  = () => {
        setFilters({});
    }

    return <div>
        <PageHeader title={title}/>

        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'justy-between', justifyContent: 'space-between' }}>
            <Search allowClear  placeholder="Buscar..." style={{ width: '100%', maxWidth: 300 }} onSearch={onSearch} />
           { createForm && typeof (createForm) == 'function' && <Button onClick={() => setCreateVisible(!createVisible)} type="default">+ Nuevo registro</Button>}

            { typeof children == 'function' ?  children({ filterText, page, filters, applyFilter, resetFilters}) : children}

            {(createForm && typeof (createForm) == 'function')  && <CreateForm total={data?.meta.total} onSuccess={refetch} onCancel={onCancelOrClose} title='Nuevo registro' visible={createVisible} method='POST' />} 
            
            {(editForm && typeof (editForm) == 'function') && <EditForm edit={editId} onSuccess={refetch} onCancel={onCancelOrClose} title='Editar registro' visible={editVisible} method='PATCH' />} 
        </div>

        <Table size="middle" rowKey={record => record.id} onChange={handleTableChange} pagination={{ pageSize: 10, current: page, total: data?.meta.total }} loading={isLoading} dataSource={data?.data} columns={columns} /></div>
}

export default CustomTable;