import {
  Form,
  message,
  Button,
  Input,
  Card,
  PageHeader,
  Select,
  Switch,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import modificationService from "../../services/modification.service";
import { downloadPDF } from "../../Helpers/Functions";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import certificationService from "../../services/certification.service";

const GNVInicial = ({ mode = "create" }) => {
  const [form] = Form.useForm();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [features, setFeatures] = useState([
    {
      type: "reductor",
      component: "REDUCTOR",
      brand: null,
      model: null,
      serie: null,
      capacity: null,
      year: null,
      weight: null,
    },

    {
      type: "cylinder",
      component: "CILINDRO 1",
      brand: null,
      model: 'CILINDRICO',
      serie: null,
      capacity: null,
      year: null,
      weight: null,
    },
  ]);

  const { data, isFetching, refetch } = useQuery(
    ["certifications", id],
    () => modificationService.getModification(id),
    { keepPreviousData: false, enabled: id > 0, refetchOnWindowFocus: false }
  );

  const addItem = () => {
    const items = [...features];

    const cylinderCount = features.filter(a => a.type == 'cylinder').length;

    items.push({
      type: "cylinder",
      component: "CILINDRO " + (cylinderCount + 1),
      brand: null,
      model: 'CILINDRICO',
      serie: null,
      capacity: null,
      year: null,
      weight: null,
    });

    setFeatures(items);
  };

  const removeElement = (index) => {
    const items = [...features];
    items.splice(index, 1);
    setFeatures(items);
  };

  useEffect(() => {
    if (data && data?.modification) {
      const field = {
        ...data.modification,
        has_chip: data.modification.has_chip === 1,
        fuel: data.modification.fuel ?? "BI-COMBUSTIBLE GNV",
        date: moment(data.certification.date) ?? moment(),
      };
      form.setFieldsValue(field);
      if (data.modification.features.length > 0) {
        setFeatures(data.modification.features);
      }
    }

    if (data) {
      document.title = `#${data?.certification.id} / ${data?.certification.owner1} / Modificaciones`;
    }

    form.setFieldsValue({
      date: moment(),
      net_weight: data?.modification?.net_weight || data?.certification.dry_weight ,
      certificate_number: data?.certification.certificate_number ?? "DG-18-0",
    });
  }, [data, form]);

  const finish = async (values) => {
    const res = await modificationService.postGLP(id, { ...values, features });

    if (!res.success) {
      message.error(res.message);
      return;
    }
    message.success(res.message);
  };

  const changeText = (e, index, field) => {
    const feat = { ...features[index] };
    feat[field] = e.target.value;
    features[index] = feat;
    setFeatures((features) => [...features]);

    if (field === "weight") {
      updateNetWeight(e);
    }
  };

  const certificateNow = async () => {
    setRequired(true);

    setTimeout(async () => {
      form
        .validateFields()
        .then(async (values) => {
          setLoading(true);
          await modificationService.postGLP(id, { ...values, features });
          const res = await certificationService.certificate(id);
          setLoading(false);

          if (!res.success) {
            message.error(res.message);
            return;
          }
          message.success(res.message);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          message.warn(err?.message || "¡Por favor completa los campos!");
        });
    }, 150);
  };

  const updateNetWeight = (e) => {
    const { certification } = data;
    const base = Number(certification.dry_weight);

    const weights = features.reduce(
      (accumulator, a) => accumulator + Number(a.weight),
      0
    );

    const total = base + weights;

    form.setFieldsValue({
      net_weight: total,
    });
  };

  const insNumber = `${data?.certification?.plate}-${data?.certification?.certificate_number}`

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Placa: ${data?.certification?.plate} / ${data?.certification?.inspection_number ?? ""
          }`}
        subTitle={moment(data?.certification.date).format("LL")}
        extra={[
          data?.certification.status === "finished" && (
            <Button
              icon={<FilePdfOutlined />}
              key="show"
              onClick={() =>
                downloadPDF(true, insNumber, data?.certification.pdf_url)
              }
              loading={loading}
            >
              Ver
            </Button>
          ),

          data?.certification.status === "finished" && (
            <Button
              icon={<DownloadOutlined />}
              key="download"
              onClick={() =>
                downloadPDF(false, insNumber, data?.certification.pdf_url)
              }
              loading={loading}
            >
              Descargar
            </Button>
          ),

          <Button
            disabled={isFetching}
            key="finish"
            loading={loading}
            onClick={certificateNow}
          >
            Certificar
          </Button>,

          mode === "edit" && (
            <Button key="modifications">Modificaciones</Button>
          ),
          <Button key="save" type="primary" onClick={() => form.submit()}>
            Guardar
          </Button>,
        ]}
      />

      <Form
        size="small"
        className="form_no_padding"
        layout="vertical"
        form={form}
        name="control-hooks"
        initialValues={{ has_chip: true }}
        onFinish={finish}
      >
        <Card loading={isFetching} className="cardex" title="General">
          <div className="table_responsive">
            <table className="table custom_table">
              <thead>
                <tr>
                  <th>
                    <Button onClick={addItem} type="link">
                      + Nuevo cilindro
                    </Button>
                  </th>
                  <th>MARCA</th>
                  <th>MODELO</th>
                  <th>SERIE</th>
                  <th>CAPACIDAD(Lts)</th>
                  <th>PESO(Kgs)</th>
                  <th>AÑO FABRICACION</th>
                </tr>
              </thead>
              <tbody>
                {features.map((a, key) => {
                  return (
                    <tr key={key}>
                      <th>
                        <Form.Item
                          style={{ width: 130 }}
                          rules={[
                            {
                              required: true,
                              message: "¡Por favor completa el dato!",
                            },
                          ]}
                        >
                          <Select value={a.type}>
                            <Select.Option disabled value="reductor">
                              REDUCTOR
                            </Select.Option>
                            <Select.Option value="cylinder">
                              CILINDRO
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </th>
                      <td>
                        {" "}
                        <Form.Item
                          //  name="reductor_brand"
                          rules={[
                            {
                              required: true,
                              message: "¡Por favor completa el dato!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => changeText(e, key, "brand")}
                            value={a.brand}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          rules={[
                            {
                              required: false,
                              message: "¡Por favor completa el modelo!!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => changeText(e, key, "model")}
                            value={a.model}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          //  name="reductor_serie"
                          rules={[
                            {
                              required: false,
                              message: "¡Por favor completa el dato!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => changeText(e, key, "serie")}
                            value={a.serie}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Input
                          onChange={(e) => changeText(e, key, "capacity")}
                          value={a.capacity}
                          disabled={a.type === "reductor"}
                        />
                      </td>

                      <td>
                        <Input
                          onChange={(e) => changeText(e, key, "weight")}
                          value={a.weight}
                          disabled={a.type === "reductor"}
                        />
                      </td>
                      <td>
                        <Input
                          value={a.year}
                          onChange={(e) => changeText(e, key, "year")}
                          disabled={a.type === "reductor"}
                        />
                      </td>

                      <td>
                        <Button danger onClick={() => removeElement(key)}>
                          X
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {data?.certification.certification_type_id != 21 && (
            <Form.Item
              style={{ width: 240 }}
              initialValue={"BI-COMBUSTIBLE GNV"}
              name="fuel"
              label="Combusitible"
              rules={[{ required: false }]}
            >
              <Input disabled size="middle" />
            </Form.Item>
          )}

          {data?.certification.certification_type_id != 21 && (
            <Form.Item
              style={{ width: 240 }}
              name="net_weight"
              label="Peso Neto"
              rules={[{ required: false }]}
            >
              <Input disabled size="middle" />
            </Form.Item>
          )}

          <Form.Item
            name="observation"
            label="Observaciones"
            rules={[{ required: false }]}
          >
            <Input.TextArea size="middle" />
          </Form.Item>

          <Form.Item
            valuePropName="checked"
            name="has_chip"
            style={{ marginTop: 10 }}
            label="Activacion de Chip"
            rules={[{ required: false }]}
          >
            <Switch size="middle" />
          </Form.Item>

          <Form.Item
            style={{ width: 200, marginTop: 10 }}
            name="certificate_number"
            label="Número de Certificado"
            rules={[
              {
                required: required,
                message: "Ingresa el numero de certificado",
              },
            ]}
          >
            <Input size="middle" />
          </Form.Item>
          <Form.Item
            style={{ width: 200, marginTop: 10 }}
            name="date"
            label="Fecha de certificación"
            rules={[{ required: required, message: "Ingresa una fecha" }]}
          >
            <DatePicker size="middle" />
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default GNVInicial;
