import {
  Form,
  message,
  Button,
  Input,
  Card,
  PageHeader,
  DatePicker,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import modificationService from "../../services/modification.service";
import { downloadPDF } from "../../Helpers/Functions";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import certificationService from "../../services/certification.service";

const GLPInicial = ({ mode = "create" }) => {
  const [form] = Form.useForm();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [required, setRequired] = useState(false);

  const navigate = useNavigate();

  const [features, setFeatures] = useState([
    {
      type: "reductor",
      component: "REDUCTOR",
      brand: null,
      model: null,
      serie: null,
      capacity: null,
      year: null,
      weight: null,
    },

    {
      type: "cylinder",
      component: "CILINDRO ",
      brand: null,
      model: null,
      serie: null,
      capacity: null,
      year: null,
      weight: null,
    },
  ]);

  const { data, isFetching, refetch } = useQuery(
    ["certifications", id],
    () => modificationService.getModification(id),
    { keepPreviousData: false, enabled: id > 0, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      const formvalues = {
        ...data?.modification,
        ...data.certification,
        date: moment(data.certification.date) ?? moment(),
        fuel: data.modification?.fuel ?? "BI-COMBUSTIBLE GLP",
        certificate_number: data.certification.certificate_number ?? "DG-31-0",
        payload:    data?.modification?.payload || data.certification.payload,
      };

      form.setFieldsValue(formvalues);

      if (data.modification?.features.length > 0) {
        setFeatures(data.modification.features);
      }

      if (data?.certification) {
        document.title = `#${data?.certification.id} / ${data?.certification.owner1} / Modificaciones`
      }

    }
  }, [data, form]);

  const finish = async (values) => {
    const res = await modificationService.postGLP(id, { ...values, features });

    if (!res.success) {
      message.error(res.message);
      return;
    }
    message.success(res.message);
  };

  const changeText = (e, index, field) => {
    const feat = { ...features[index] };
    feat[field] = e.target.value;
    features[index] = feat;
    setFeatures((features) => [...features]);

    if (field === "weight") {
      const { certification } = data;

      const weightSaved= data?.modification?.net_weight || certification.dry_weight ;
      const payloadSaved= data?.modification?.payload || certification.payload ;

      let netWeight = Number(weightSaved) + Number(e.target.value);

      const payload = Number(payloadSaved) - Number(e.target.value);
      form.setFieldsValue({
        net_weight: netWeight,
        payload: payload,
      });
    }
  };

  const changeModel = (value, index) => {
    const feat = { ...features[index] };
    feat['model'] = value;
    features[index] = feat;
    setFeatures((features) => [...features]);
  }

  const certificateNow = async () => {
    setRequired(true);

    setTimeout(async () => {
      form
        .validateFields()
        .then(async (values) => {
          setLoading(true);
          await modificationService.postGLP(id, { ...values, features });
          const res = await certificationService.certificate(id);
          setLoading(false);

          if (!res.success) {
            message.error(res.message);
            return;
          }
          message.success(res.message);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          message.warn(err?.message || "¡Por favor completa los campos!");
        });
    }, 150);
  };

  const insNumber = `${data?.certification?.plate}-${data?.certification?.certificate_number}`

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() =>
          navigate("/certifications/" + data?.certification?.id + "/edit")
        }
        title={`Placa: ${data?.certification?.plate} / ${data?.certification?.inspection_number ?? ""
          }`}
        subTitle={moment(data?.certification.date).format("LL")}
        extra={[
          data?.certification.status === "finished" && (
            <Button
              icon={<FilePdfOutlined />}
              key="show"
              onClick={() =>
                downloadPDF(true, insNumber, data?.certification.pdf_url)
              }
              loading={loading}
            >
              Ver
            </Button>
          ),

          data?.certification.status === "finished" && (
            <Button
              icon={<DownloadOutlined />}
              key="download"
              onClick={() =>
                downloadPDF(false, insNumber, data?.certification.pdf_url)
              }
              loading={loading}
            >
              Descargar
            </Button>
          ),

          <Button
            disabled={isFetching}
            key="finish"
            loading={loading}
            onClick={certificateNow}
          >
            Certificar
          </Button>,

          <Button
            disabled={isFetching}
            key="save"
            loading={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            Guardar
          </Button>,
        ]}
      />

      <Form
        size="small"
        className="form_no_padding"
        layout="vertical"
        form={form}
        name="control-hooks"
        initialValues={{ chassis_status: "NUEVO" }}
        onFinish={finish}
      >
        <Card loading={isFetching} className="cardex" title="General">
          <div className="table_responsive">
            <table className="table custom_table">
              <thead>
                <tr>
                  <th></th>
                  <th>MARCA</th>
                  <th>MODELO</th>
                  <th>SERIE</th>
                  <th>CAPACIDAD(Lts)</th>
                  <th>PESO(Kgs)</th>
                  <th>AÑO FABRICACION</th>
                </tr>
              </thead>
              <tbody>
                {features.map((a, key) => {
                  return (
                    <tr key={key}>
                      <th>{a.component}</th>
                      <td>
                        {" "}
                        <Form.Item
                          //  name="reductor_brand"
                          rules={[
                            {
                              required: true,
                              message: "¡Por favor completa el dato!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => changeText(e, key, "brand")}
                            value={a.brand}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          valuePropName="value"
                          rules={[
                            {
                              required: false,
                              message: "¡Por favor completa el modelo!!",
                            },
                          ]}
                        >
                          {a.type == 'reductor' ?
                            <Input
                              onChange={(e) => changeText(e, key, "model")}
                              value={a.model}
                            />
                            :
                            <Select
                              value={a.model}
                              style={{ width: '100%' }}
                              showSearch
                              placeholder="Modelo"
                              optionFilterProp="children"
                              onChange={(value) => changeModel(value, key)}

                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={[
                                {
                                  value: 'CILINDRICO',
                                  label: 'CILINDRICO',
                                },
                                {
                                  value: 'TOROIDAL',
                                  label: 'TOROIDAL',
                                },
                                {
                                  value: 'LENTEJA',
                                  label: 'LENTEJA',
                                },
                              ]}
                            />
                          }
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          //  name="reductor_serie"
                          rules={[
                            {
                              required: false,
                              message: "¡Por favor completa el dato!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => changeText(e, key, "serie")}
                            value={a.serie}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Input
                          onChange={(e) => changeText(e, key, "capacity")}
                          value={a.capacity}
                          disabled={a.type === "reductor"}
                        />
                      </td>

                      <td>
                        <Input
                          onChange={(e) => changeText(e, key, "weight")}
                          value={a.weight}
                          disabled={a.type === "reductor"}
                        />
                      </td>
                      <td>
                        <Input
                          value={a.year}
                          onChange={(e) => changeText(e, key, "year")}
                          disabled={a.type === "reductor"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Form.Item
            style={{ width: 240 }}
            name="fuel"
            label="Combusitible"
            rules={[{ required: false }]}
          >
            <Input size="middle" />
          </Form.Item>

          <Form.Item
            style={{ width: 240 }}
            name="net_weight"
            label="Peso neto"
            rules={[{ required: false }]}
          >
            <Input disabled size="middle" />
          </Form.Item>

          <Form.Item
            style={{ width: 240 }}
            name="payload"
            label="Carga util"
            rules={[{ required: false }]}
          >
            <Input disabled size="middle" />
          </Form.Item>

          <Form.Item
            name="observation"
            label="Observaciones"
            rules={[{ required: false }]}
          >
            <Input.TextArea size="middle" />
          </Form.Item>

          <Form.Item
            style={{ marginTop: 10 }}
            name="certificate_number"
            label="Número de Certificado"
            rules={[
              {
                required: required,
                message: "Ingresa el numero de certificado",
              },
            ]}
          >
            <Input style={{ width: 240 }} size="middle" />
          </Form.Item>

          <Form.Item
            style={{ marginTop: 10 }}
            name="date"
            label="Fecha de certificación"
            rules={[{ required: required, message: "Ingresa una fecha" }]}
          >
            <DatePicker style={{ width: 240 }} size="middle" />
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default GLPInicial;
