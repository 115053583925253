import { Select } from "antd";
import React, { forwardRef, useEffect, useState } from "react"
import apiClient from "../../services/api";

import PropTypes from 'prop-types';

const {Option} = Select

const SelectAjax = forwardRef((props, ref) => {

    const [options, setOptions] = useState([]);
    const {url, displayProp,valueProp} = props;

    const getValues = async () => {
       const res = await apiClient.get(url)
       const values  =[{id: "", name: 'Selecciona'}, ...res?.data]
        setOptions(values ?? [])
    }

    useEffect(()=> {
            getValues() 
    },[]);

    return <Select
    placeholder = "Seleccciona"
    allowClear
    {...props}
    >{options.map((a) => <Option key={a[valueProp] ?? a.id} value={a[valueProp]??a.id}>{a[displayProp]??a.name}</Option> )}
            </Select >
})

SelectAjax.propTypes = {
    url: PropTypes.string.isRequired,
};

export default SelectAjax;